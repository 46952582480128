import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import imageOne from "../../assets/profilepic.jpg";
import supplyChain from "../../assets/images/SUPPLY CHAIN.jpg";
import graphicsImage from "../../assets/images/graphics.jpg";
import artImage from "../../assets/images/ThreeD_EVELOPMENT.jpg"
import Fade from "react-reveal";

export class Trend extends Component {
  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
    let projectImage = "images/profilepic.jpg";
    return (
      <section id="collection" className="services_main">
        <Fade left duration={1000} distance="40px">
          <div className="row consumers_brand_part">
            <h3 className="service_header upper_case header_text_size">We produce precisely <span className="color_span"> what you require</span></h3>
            {/* <p><span>DEZININ </span> helps you create the right decision for your business</p> */}
            <br />
          </div>

          <div className="row">
            <Grid container spacing={0} className="full_part_div">
              <Grid item xs={6} md={6} className="half_part_div">
                <div className="half_part_div_second_parent">
                  <div className="half_part_div_second">
                    <div>
                      <h6 className="header_text_size">GRAPHICS & DESIGN</h6>
                    </div>
                    <div>
                      {/* <h4>Sale Now One 300% off event</h4> */}
                    </div>
                    <div>
                      <p>
                        Trendy graphics in fashion now embrace bold mainimalism and nostagic elements, seamlessly blending modern aesthetics with vintage charm to create eye-catching and timeless style statements.
                      </p>
                    </div>
                    {/* <div>
                      <button className="explore_all_button">
                      Explore all ->
                      </button>
                    </div> */}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={6} className="grid_image_part">
                <img src={graphicsImage} width="600" height="600" />
              </Grid>
            </Grid>
          </div>
          <div className="mobile_view_space"></div>
          <div className="row">
            <Grid container spacing={0} className="full_part_div">
              <Grid item xs={6} md={6} className="grid_image_part">
                <img src={artImage} width="600" height="600" />
              </Grid>
              <Grid item xs={6} md={6} className="half_part_div">
                <div className="half_part_div_second">
                  <div>
                    <h6 className="header_text_size">3D & DEVELOPMENT</h6>
                  </div>
                  <div>
                    {/* <h4>Sale Now One 300% off event</h4> */}
                  </div>
                  <div>
                    <p>
                      innovation and efficiency in the industry. This cutting-edge approach not only accelerates the production timeline but also enhances sustainability by reducing the need for physical samples.
                    </p>
                  </div>
                  {/* <div>
                    <button className="explore_all_button">
                      Explore all ->
                    </button>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mobile_view_space"></div>

          <div className="row">
            <Grid container spacing={0} className="full_part_div">
              <Grid item xs={6} md={6} className="half_part_div">
                <div className="half_part_div_second">
                  <div>
                    <h6 className="header_text_size">SUPPLY CHAIN</h6>
                  </div>
                  <div>
                    {/* <h4>Sale Now One 300% off event</h4> */}
                  </div>
                  <div>
                    <p>
                      Supply chain encompasses the intricate network of processes, from design to production and distribution, crucial in delivering trendsetting clothing to consumers globally. Balancing creativity and efficiency, it involves sourcing materials, manufacturing, logistics, and retail, constantly adapting to market demands and sustainability imperatives.
                    </p>
                  </div>
                  {/* <div>
                    <button className="explore_all_button">
                      Explore all ->
                    </button>
                  </div> */}
                </div>
              </Grid>
              <Grid item xs={6} md={6} className="grid_image_part">
                <img src={supplyChain} width="600" height="600" />
              </Grid>
            </Grid>

          </div>
        </Fade>
      </section>
    );
  }
}

export default Trend;
