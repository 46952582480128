import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import imageOne from "../../assets/profilepic.jpg";
import coverOne from "../../assets/images/COVER-01.jpg";
import Fade from "react-reveal";
import { Link } from "react-router-dom";

export class Trend extends Component {
  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
    return (
      <section id="collection" className="trend_section">
        <Fade left duration={1000} distance="40px">
        <div className="row">
        <h3 className="trend_home_header upper_case header_text_size">EXPERT CONSUMER TREND FORECASTING </h3>
<p className="trend_para_p">
We do this through consumer insight, product design direction and trading strategies so you can create sustainably and land the right trends at the right time to maximise sales.
</p>
          </div>
        <div className="row">
      

            <Grid container spacing={0} className="full_part_div">
             
             
              {/* <Grid item xs={6} md={6} className="half_part_div">
              <div className="half_part_div_second">
              <div>
                  <h6>New In Trend</h6>
                </div>
                <div>
                  <h4>Sale Now One 300% off event</h4>
                </div>
                <div>
                  <p>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder text commonly used to demonstrate the visual
                    form of a document or a typeface without relying on
                    meaningful content.
                  </p>
                </div>
                <div>
                  <Link to="/trend">Explore all -></Link>
                 
                </div>
              </div>
              </Grid>
              <Grid item xs={6} md={6} className="grid_image_part">
                <img src={imageOne} width="600" height="600" />
              </Grid> */}
              <Grid item xs={12} md={12} className="grid_image_part trend_img_part">
                <img src={coverOne} width="600" height="600" />
               <div className="trend_link_part">
               <Link to="/trend">
                <button className="explore_all_button_without_background upper_case">
                      Explore all Trend->
                      </button>
                </Link>
               </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Trend;
