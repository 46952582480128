import React, { Component } from "react";
import ComonComponent from "./CommonComponent";
import Collections from "../homePage/Collections";
import ReactGA from "react-ga";
import $ from "jquery";
import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder,
} from "@cloudinary/react";
import imageOne from "../../assets/images/1.jpg";
import JoinUsMembership from "../homePage/JoinUsMembership";
import Grid from "@mui/material/Grid";
export class Knit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      department_name: "",
      foo: "bar",
      resumeData: {},
      all:true,
      mens:false,
      ladies:false,
      kids:false
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }
  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }
  categorySelect=(data)=>{
    if(data ==="all"){
      this.setState({
      all:true,
      mens:false,
      ladies:false,
      kids:false
      })
    }else if(data ==="mens"){
      this.setState({
      all:false,
      mens:true,
      ladies:false,
      kids:false
      })
    }
    if(data ==="ladies"){
      this.setState({
      all:false,
      mens:false,
      ladies:true,
      kids:false
      })
    }
    if(data ==="kids"){
      this.setState({
      all:false,
      mens:false,
      ladies:false,
      kids:true
      })
    }
    console.log("data_check",data)
  }

  componentDidMount() {
    this.getResumeData();
  }
  render() {
    return (
      <div>
      <br/>
      <br/>
      <br/>
      <br/>
     
        <Grid container spacing={0} className="knit_both_side">
        <Grid item xs={2}>
        <div className="knit-common_left_side">
            <ul>
              <li onClick={()=>this.categorySelect("all")}>All</li>
              <li  onClick={()=>this.categorySelect("mens")}>Mens</li>
              <li  onClick={()=>this.categorySelect("ladies")}>Ladies</li>
              <li  onClick={()=>this.categorySelect("kids")}>Kids</li>
            </ul>
          </div>
          </Grid>
          <Grid item xs={10}>
          <div className="knit_common">
            {this.state.all && <ComonComponent data={this.state.resumeData.all} />}
            {this.state.mens && <ComonComponent data={this.state.resumeData.mens} />}
            {this.state.ladies && <ComonComponent data={this.state.resumeData.ladies} />}
            {this.state.kids && <ComonComponent data={this.state.resumeData.kids} />}
            
          </div>
          </Grid>
        </Grid>
        <div className="knit_collections">
          
         
        </div>
        <div className="clear_both"></div>
      </div>
    );
  }
}

export default Knit;
