import React, { Component } from "react";
import Fade from "react-reveal";
import artImage from "../../assets/images/story.png";
import WomenEmpower from "../../assets/images/Womenempower.jpg";
import Sustainable from "../../assets/images/sustainable.webp";
import coverOne from "../../assets/images/coverOne.png"
import coverTwo from "../../assets/images/coverTwo.jpg"
import trendOne from "../../assets/images/COVER-01.jpg"
import trendTwo from "../../assets/images/COVER-02.jpg"
import trendThree from "../../assets/images/COVER-03.jpg"
import TrendFullImage from "../../assets/images/DESIGN PACKAGE1-01_trend.jpg"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import JoinUsMembership from "./JoinUsMembership";


export class DezininCarosol extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

    return (
      <section id="collection" className="collection_carosol trend_comp_main">
        {/* <Fade  distance="40px"> */}
        <div className="row">
          <div style={{ textAlign: "center" }}>
            {/* <h2>React Carousel Minimal</h2>
          <p>Easy to use, responsive and customizable carousel component for React Projects.</p> */}
            <div
            // style={{
            //   padding: "0 20px",
            // }}
            >
              <Carousel swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px">
                <div className="carosel_part">
                  <img src={trendOne} />
                  {/* <p className="legend">Crafting a Greener
                    <br />
                    Tomorrow</p> */}
                </div>
                <div className="carosel_part">
                  <img src={trendTwo} />
                  {/* <p className="legend"> Innovative Design
                    <br />
                    Transforms Production Landscape!</p> */}
                </div>
                <div className="carosel_part">
                  <img src={trendThree} />
                  {/* <p className="legend"> Exceptional speed
                    <br />
                    and excellence</p> */}
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="row trend_image_full">
          <div>
            <h6 className="header_text_size">DEZININ OFFERS FASHION DESIGN SERVICES THAT AIMS TO ASSIST AND GUIDE START UP FASHION BRANDS, FACTORIES AND BUSINESS.</h6>
          </div>
          <img src={TrendFullImage} />
        </div>
        <div className="row">
        <JoinUsMembership/>

        </div>
        {/* </Fade> */}
      </section>
    );
  }
}

export default DezininCarosol;
