import React, { Component } from 'react'

export class Woven extends Component {
  render() {
    return (
        <div>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <h3>Woven</h3>
        <p>
        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedia

        </p>
        <p>
        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedia

        </p>
        <p>
        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedia

        </p>
      </div>
    )
  }
}

export default Woven