import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import imageOne from "../../assets/profilepic.jpg";
import Fade from "react-reveal";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export class JoinUsMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmail: ""
    };
  }
  submitEmail = () => {
    console.log("fffffffffff function call");
    let isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (this.state.sendEmail && this.state.sendEmail.match(isValidEmail)) {
      axios({
        method: 'POST',
        // url: 'http://localhost:5000/sendemail',
        url: 'https://send-email-one.vercel.app/sendemail',
        data: {
          email: this.state.sendEmail,
        },
      }).then((res) => {
        console.log("response ", res)
        if (res.status == 200) {
          this.setState({ sendEmail: "" })
          toast("Successfully send your mail")
        } else {
          toast("Something wrong")

        }
      }).catch((err) => {
        console.log("response err", err)
      });
    } else {
      console.log("fffffffffff email validation false")
      // showNoValidEmail(true);
    }
  }
  handleEmailChange = (e) => {
    this.setState({ sendEmail: e.target.value })
  }
  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));

    return (
      <section id="collection">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <Grid container spacing={2} className="join_us_membership_main">
              <Grid item xs={11} md={5} className="joinusmembership_top_grid">
                <div className="joinusmembership_top_grid_div">
                  <h3 className="text_align_center">Get monthly trend insights</h3>
                  <p className="text_align_center">
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder text commonly used to demonstrate the visual
                    form of a document or a typeface without relying on
                    meaningful content.
                  </p>
                </div>
              </Grid>

              <Grid container spacing={2} xs={11} md={5} className="joinusmembership_top_grid">
                <Grid item xs={8} md={9} className="">
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder="Your email.."
                    onChange={(e) => { this.handleEmailChange(e) }}
                    value={this.state.sendEmail}
                  />
                </Grid>
                <Grid item xs={4} md={3} className="submit_button">
                  <div>
                    <button className="explore_all_button submit_button" onClick={() => this.submitEmail()}>Submit -></button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </section>
    );
  }
}

export default JoinUsMembership;
