import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import imageOne from "../../assets/images/OUR STORY.jpg";
import Fade from "react-reveal";

export class OurPromise extends Component {
  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
    return (
      <section id="collection" className="our_story_main">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <Grid container spacing={0} className="full_part_div">


              <Grid item xs={6} md={6} className="half_part_div_sustainability">
                <div className="half_part_div_second our_story_part">

                  <div>
                    <h2 className="header_p header_text_size">OUR STORY</h2>
                  </div>
                  <div>
                    <p>
                      DEZININ was established in 2019 with the goal of becoming the world's leading design
                      lab and generating thousands of original concepts every year to support sustainable
                      fashion and environmental conservation. Founded in our dedication to protecting the
                      environment and people,
                    </p>
                    <p>
                      We have positioned ourselves as a company committed to changing the fashion and apparel industry for the better
                    </p>
                    <p>
                      Our goal is to provide firms with a flexible supply chain so that ethical fashion decisions
                      may be more easily and affordably made. At DEZININ, we are proud of our effcient procedures that enable us to quickly bring innovative concepts to life in an astounding 45-day
                      period, making us stand out as an industry leader in productivity
                    </p>
                  </div>
                  {/* <div>
                    <button className="explore_all_button sustainablity">
                      Our Promise ->
                    </button>
                  </div> */}
                </div>
              </Grid>
              <Grid item xs={6} md={6} className="grid_image_part">
                <img src={imageOne} width="600" height="600" />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </section>
    );
  }
}

export default OurPromise;
