import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Topbar from "./Topbar";
import imageOne from "../assets/images/homeLogo.png";

export class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileView: false
    }
  }
  handleMobileView() {
    if (this.state.mobileView) {
      this.setState({ mobileView: false })
    } else {
      this.setState({ mobileView: true })
    }
  }
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top header_part">
        <div>
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                onClick={() => this.handleMobileView()}
              >
                {" "}
                <span className="sr-only"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <Link className="navbar-brand page-scroll logo_dezinin" to="/">
                <img src={imageOne} width="200" height="200" />
              </Link>
              {/* <a className="navbar-brand page-scroll" href="/">
              Dezinin
            </a>{" "} */}
            </div>

            <div
              className={`collapse navbar-collapse ${this.state.mobileView ? " display_block" : " display_none"}`}
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-left">
                {/* <li>
                <a href="#features" className="page-scroll">
                  Features
                </a>
              </li> */}
                <li className="trend_list">
                  <Link to="/trend" className="page-scroll trend_item">TREND</Link>
                  {/* <a href="#about" className="page-scroll trend_item">
                  TREND
                </a> */}
                  {/* <div class="dropdown-content_trend">
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
              </div>     */}
                </li>
                <li className="service_list" >
                  <Link to="/services" className="page-scroll service_item">SERVICES</Link>
                  <div class="dropdown-content_service">
                    <Link to="/graphics-design" onClick={() => this.handleMobileView()}>Graphics & Design</Link>
                    <Link to="/3d-development" onClick={() => this.handleMobileView()}>3D & Development</Link>
                    <Link to="/supply-chain-production" onClick={() => this.handleMobileView()}>Supply Chain / Production</Link>
                  </div>
                </li>
                <li>
                  <Link to="/" className="page-scroll">SUSTAINABILITY</Link>
                  {/* <a href="#sustainability" className="page-scroll">
                  SUSTAINABILITY
                </a> */}
                </li>
                {/* <li>
                <a href="#testimonials" className="page-scroll">
                  Testimonials
                </a>
              </li> */}
                <li className="collection_list">
                  <Link to="/" className="page-scroll collection_item">COLLECTION</Link>
                  {/* <a href="/" className="page-scroll collection_item">
                  COLLECTION
                </a> */}
                  <div class="dropdown-content_collection">
                    <Link to="/knit" onClick={() => this.handleMobileView()}>Knit</Link>
                    <Link to="/woven" onClick={() => this.handleMobileView()}>woven</Link>
                    <Link to="/denim" onClick={() => this.handleMobileView()}>Denim</Link>
                    <Link to="/sweater" onClick={() => this.handleMobileView()}>Sweater</Link>
                    <Link to="/outerwear" onClick={() => this.handleMobileView()}>Outerwear</Link>
                    <Link to="/active-wear" onClick={() => this.handleMobileView()}>Active Wear</Link>
                    {/* <a href="knit">Knit</a>
                  <a href="woven">Woven</a>
                  <a href="#">Denim</a>
                  <a href="#">Sweater</a>
                  <a href="#">Outerwear</a>
                  <a href="#">Active Wear</a> */}
                  </div>
                </li>
                {/* <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li> */}

              </ul>
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="#about" className="page-scroll">
                    OUR PROMISE
                  </a>
                </li>
                <li>
                  <a href="#services" className="page-scroll">
                    CONTACT
                  </a>
                </li>
                <li>
                  <a href="#sustainability" className="page-scroll">
                    JOIN US MEMBERSHIP
                  </a>
                </li>
              </ul>
            </div>


          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
