import React, { Component } from "react";
import Collections from "../homePage/Collections";
import ReactGA from "react-ga";
import $ from "jquery";
import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder,
} from "@cloudinary/react";
import imageOne from "../../assets/images/1.jpg";
import JoinUsMembership from "../homePage/JoinUsMembership";
export class ThreeDDevelopment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      department_name: "",
      foo: "bar",
      resumeData: {},
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }
  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <div>
        <div className="image_and_text">
          <img
            src={imageOne}
            alt="Nature"
            class="responsive_img"
            width="600"
            height="400"
          />
          <div class="centered_text">
            <h1 className="image_text_header">3D & Development</h1>
            <br />
            <p>Trend Flash</p>
            <h4>Chintzy Floras</h4>
            <button>Explore now</button>
          </div>
        </div>
        <div className="new_arrivals">
          <Collections
            title={"New Arrivals"}
            data={this.state.resumeData.new_arrival}
          />
        </div>
        <div className="shop_by_category">
          <Collections
            title={"Shop By Category"}
            data={this.state.resumeData.collection}
          />
        </div>
        <div>
          <JoinUsMembership />
        </div>
      </div>
    );
  }
}

export default ThreeDDevelopment;
