import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//redux
import { Provider } from "react-redux";
import store from "./store";
import Login from "./components/login/Login";
import Registration from "./components/registration/Registration";
import Home from "./components/homePage/home";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import Knit from "./components/Collections/Knit";
import Woven from "./components/Collections/Woven";
import Denim from "./components/Collections/Denim";
import Sweater from "./components/Collections/Sweater";
import Outerwear from "./components/Collections/Outerwear";
import ActiveWear from "./components/Collections/ActiveWear";
import GraphicsAndDesign from "./components/Services/GraphicsAndDesign";
import ThreeDDevelopment from "./components/Services/ThreeDDevelopment";
import SupplyChain from "./components/Services/SupplyChain";
import TrendComp from "./components/homePage/TrendComp";
// import 'bootstrap/dist/css/bootstrap.min.css';
class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Provider store={store}>
          <React.StrictMode>
          <Navigation />

{/* <br /> */}
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/registration" component={Registration} />
              <Route exact path="/" component={Home} />
              <Route exact path="/knit" component={Knit} />
              <Route exact path="/trend" component={TrendComp} />
              <Route exact path="/woven" component={Woven} />
              <Route exact path="/denim" component={Denim} />
              <Route exact path="/sweater" component={Sweater} />
              <Route exact path="/outerwear" component={Outerwear} />
              <Route exact path="/active-wear" component={ActiveWear} />
              <Route exact path="/graphics-design" component={GraphicsAndDesign} />
              <Route exact path="/3d-development" component={ThreeDDevelopment} />
              <Route exact path="/supply-chain-production" component={SupplyChain} />
            </Switch>    
            <Footer/>        
            {/* <App /> */}
          </React.StrictMode>
        </Provider>
      </BrowserRouter>
    );
  }
}
ReactDOM.render(<Root />, document.getElementById("root"));

// serviceWorker.unregister();
